import '../css/app.pcss';

import { Luminous } from 'luminous-lightbox';

import objectFitImages from 'object-fit-images';

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}

// CSS object-fit polyfill
objectFitImages();

// Main menu
let menuItems = document.querySelectorAll('li.more');
Array.prototype.forEach.call(menuItems, function(el, i){
    let btn = '<button><span><span class="visually-hidden">show submenu</span></span></button>';
    let topLevelLink = el.querySelector('a.has-submenu');
    topLevelLink.innerHTML = topLevelLink.innerHTML + ' ' + btn;

    el.querySelector('a button').addEventListener("click",  function(event){
        if (this.parentNode.parentNode.classList.contains("open")) {
            this.parentNode.parentNode.classList.remove("open");
            this.parentNode.setAttribute('aria-expanded', "false");
            this.querySelector('.visually-hidden').innerText = 'show submenu';
        } else {
            this.parentNode.parentNode.classList.add("open");
            this.parentNode.setAttribute('aria-expanded', "true");
            this.querySelector('.visually-hidden').innerText = 'hide submenu';
        }
        event.preventDefault();
    });
});
(function() {
    // get button and menu nodes
    let button = document.querySelector('#nav-button');
    let menu = button.nextElementSibling;
    // set initial (closed menu) states
    button.setAttribute('aria-expanded', 'false');
    button.hidden = false;
    menu.hidden = true;
    button.addEventListener('click', function() {
        // toggle menu visibility
        let expanded = this.getAttribute('aria-expanded') === 'true';
        this.setAttribute('aria-expanded',String(!expanded));
        menu.hidden = expanded;
    });
})();

// Luminous lightbox
(function() {
    // get lightbox images
    let lightboxImages = document.querySelectorAll(".luminous");
    let options = {
        caption: function(elem) {
            if (elem.querySelector('figcaption')) {
                return elem.querySelector('figcaption').innerText
            }
            return '';
        }
    };
    Array.prototype.forEach.call(lightboxImages, function(el, i) {
        new Luminous(el, options);
    });
})();

// Lazy load embedded YouTube videos
( function() {

    let youtube = document.querySelectorAll( ".youtube" );

    for (let i = 0; i < youtube.length; i++) {

        let source = "https://img.youtube.com/vi/"+ youtube[i].dataset.embed +"/sddefault.jpg";

        let image = new Image();
        image.src = source;
        image.addEventListener( "load", function() {
            youtube[ i ].appendChild( image );
        }( i ) );

        youtube[i].addEventListener( "click", function() {

            let iframe = document.createElement( "iframe" );

            iframe.classList.add('w-full','h-full');

            iframe.setAttribute( "frameborder", "0" );
            iframe.setAttribute( "allowfullscreen", "" );
            iframe.setAttribute( "src", "https://www.youtube.com/embed/"+ this.dataset.embed +"?rel=0&showinfo=0&autoplay=1" );

            this.innerHTML = "";
            this.appendChild( iframe );
        } );
    };

} )();

// B&L livestream and sessions sponsor flickity carousel
(function() {
    let carouselContainers = document.querySelectorAll('[data-carousel-container]');
    let carousels = [];

    if (carouselContainers) {
        let flktyOptions = {
            "wrapAround":true,
            "cellAlign":"left",
            "prevNextButtons":true,
            "pageDots":false,
            "autoPlay":10000
        };

        let widthOptions = [
            {
                "width": 0,
                "slides": 3
            },
            {
                "width": 992,
                "slides": 4
            },
            {
                "width": 1200,
                "slides": 5
            },
        ];

        const checkSize = function(carousel, index) {
            let initFlkty = true;
            if (carousel) {
                let slideCount = carousel.parentNode.parentNode.querySelector('[data-slide-count]');
                let numSlides;
                if (slideCount) {
                    numSlides = slideCount.dataset.slideCount;
                }

                if (numSlides) {
                    widthOptions.forEach(function(opt) {
                        if (window.innerWidth >= opt.width && numSlides <= opt.slides) {
                            initFlkty = false;
                        }
                    });
                    if (carousels[index]) {
                        carousels[index].destroy();
                        carousels[index] = null;
                    }
                    if (initFlkty) {
                        carousels[index] = new Flickity(carousel, flktyOptions);
                    }
                }
            }
        };

        Array.prototype.forEach.call(carouselContainers, function(item, index) {
            let speed = item.querySelector('[data-carousel-speed]');
            if (speed) {
                speed = parseInt(speed.dataset.carouselSpeed);
                flktyOptions["autoPlay"] = speed;
            }
            let carousel = item.querySelector('[data-carousel]');

            checkSize(carousel, index);
        });

        window.addEventListener('resize', function(ev) {
            Array.prototype.forEach.call(carouselContainers, function(item, index) {
                checkSize(item.querySelector('[data-carousel]'), index);
            });
        });
    }
})();

// Detect pages with protected areas and prevent text selection and copying
(function() {
    // Test for .protected content
    let noCopy = document.querySelectorAll('.protected');

    if (noCopy.length) {
        document.addEventListener('copy', function(ev) {
            window.getSelection().removeAllRanges();
            ev.preventDefault();
        });
        document.addEventListener('cut', function(ev) {
            window.getSelection().removeAllRanges();
            ev.preventDefault();
        });
    }
})();